import React from 'react'
import moment from 'moment';
import { ColumnsType } from '../../assets/types/baseApi';

/** 设备类型 */
export enum EndpointType {
  'P200Box' = 4,
  'M100Box' = 9,
  'M200Box' = 10,
  'MiniBox' = 11,
  'MBox' = 12,
}

export type DetailType = {
  title: string
  detail: {
    label: string
    value: string
    render?: (val: string, record: any) => React.JSX.Element | string
  }[]
}

const getEndpointType = (type: EndpointType) => {
  switch (type) {
    case EndpointType.P200Box:
      return 'P200-Box';
    case EndpointType.M100Box:
      return 'M100-Box';
    case EndpointType.M200Box:
      return 'M200-Box';
    case EndpointType.MiniBox:
      return 'Mini-Box';
    case EndpointType.MBox:
      return 'M-Box';
    default:
      return '--';
  }
};

const voltage_calibration_map = {
  0: '未校准',
  1: '已校准'
};

const renderLiquidLevel = (text: number) => {
  if (text === 1) {
    return '液位缺油';
  } else if (text === 2) {
    return '中等液位';
  } else if (text === 3) {
    return '液位充足';
  } else {
    return '液位未知';
  }
};

export const deviceColumn: (cb: (record: any) => void) => ColumnsType[] = (cb) => [{
  title: '产品型号',
  dataIndex: 'model_code',
  key: 'model_code',
},{
  title: '机构名称',
  dataIndex: 'org_name',
  key: 'org_name',
}, {
  title: '标识号',
  dataIndex: 'version_code',
  key: 'version_code',
  width: 350,
  render: (_: any) => <pre style={{ whiteSpace: "pre-line"}}>
    {_}
  </pre>
},{
  title: '序列号',
  dataIndex: 'serial_number',
  key: 'serial_number',
},{
  title: '硬件ID',
  dataIndex: 'hardware_id',
  key: 'hardware_id',
  },{
  title: '设备类型',
  dataIndex: 'endpoint_type',
  key: 'endpoint_type',
  render: (_: any, record: any) => getEndpointType(record.endpoint_type)
  }, {
  title: '创建时间',
  dataIndex: 'created_at',
  key: 'created_at',
  render: (_: any, record: any) => moment(_).format('YYYY-MM-DD HH:mm:ss')
  }, {
  title: '最后更新',
  dataIndex: 'updated_at',
  key: 'updated_at',
  render: (_: any, record: any) => moment(_).format('YYYY-MM-DD HH:mm:ss')
  }, {
  title: '详情',
  dataIndex: 'opera',
  key: 'opera',
  render: (_: any, record: any) => record.has_changelog && <a onClick={() => cb(record)}>详情</a>
  }]

export const detailConfig: DetailType[] = [{
  title: '基本信息',
  detail: [{
    label: '产品名称',
    value: 'product_name',
  },{
    label: '标识号',
    value: 'version_code',
  },{
    label: '产品型号',
    value: 'model_code',
  },{
    label: '序列号',
    value: 'serial_number',
  },{
    label: '硬件ID',
    value: 'hardware_id',
  },{
    label: '设备类型',
    value: 'endpoint_type',
    render: (_: any, record: any) => getEndpointType(record.endpoint_type)
  },{
    label: '机构名称',
    value: 'org_name',
  }, {
    label: '修改时间',
    value: 'updated_at',
    render: (val: string, record: any) => moment(record[val]).format('YYYY-MM-DD HH:mm:ss')
  }]
}, {
  title: '服务管理',
  detail: [{
    label: '服务版本',
    value: 'server.version',
  },{
    label: '数据库版本',
    value: 'server.mysql_version',
  },{
    label: 'Gateway版本',
    value: 'gateway.version',
  }]
}, {
  title: '视图管理',
  detail: [{
    label: 'SLBS版本',
    value: 'slbs.version',
  },{
    label: '序列号',
    value: 'slbs.serial_number',
  }]
}, {
  title: 'TMS',
  detail: [{
    label: '固件版本',
    value: 'tms.firmware_version',
  },{
    label: '是否校准',
    value: 'tms.voltage_calibration',
    render: (val: string, record: any) => voltage_calibration_map[record[val] as 0 | 1]
  },{
    label: '斜率',
    value: 'tms.voltageB',
  },{
    label: '截距',
    value: 'tms.voltageK',
  }]
}, {
  title: '线圈',
  detail: [{
    label: '线圈类型',
    value: 'coil.type',
  },{
    label: '序列号',
    value: 'coil.serial_number',
  },{
    label: '固件版本',
    value: 'coil.firmware_version',
  },{
    label: '生产时间',
    value: 'coil.production_date',
  },{
    label: '线圈寿命',
    value: 'coil.life',
  }]
}, {
  title: '液冷',
  detail: [{
    label: '固件版本',
    value: 'cooling.firmware_version',
  },{
    label: '液位',
    value: 'cooling.liquid_level',
    render: (text: string, record: any) => renderLiquidLevel(parseInt(record[text]))
  },{
    label: '流速（单位：ml/秒）',
    value: 'cooling.water_pump_flow_rate',
  }]
}]

