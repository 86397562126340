import React, { Fragment, useEffect, useState } from 'react'
import styles from './index.module.less'
import { detailConfig } from '../../config'
import { Select, Switch } from 'antd'
import { request } from '../../../../utils/request'
import { flatObj } from '../../../../utils/common'
import moment from 'moment'

type Props = {
  detail: any
  open: boolean
}

enum SwitchType {
  /** 未选择 */
  none = 0,
  /** 对比本次修改 */
  pre = 1,
  /** 对于当前区别 */
  cur = 2,
}

export const DetailItem = (props: Props) => {
  const { detail, open } = props
  const [renderList, setRenderList] = useState<any>([])
  const [logs, setLogs] = useState<any[]>([])
  const [active, setActive] = useState<number>()
  const [checkedList, setCheckedList] = useState<SwitchType>(SwitchType.none)

  const handleChangeDiff = (type: SwitchType) => (checked: boolean) => {
    const current = logs.find(v => v.value === active)
    if (!checked) {
      setCheckedList(SwitchType.none)
      return setRenderList([current, current]);
    }
    setCheckedList(type)
    if (type === SwitchType.pre) {
      const current_index = logs.findIndex(v => v.value === active)
      setRenderList([logs[Math.min(current_index + 1, logs.length - 1)], current])
    } else {
      setRenderList([current, logs[0]])
    }
  }

  const handleSelect = (id: number) => {
    const current = logs.find(v => v.value === id)
    setActive(id)
    setCheckedList(SwitchType.none)
    setRenderList([current, current]);
  }

  const fetchLogs = async () => {
    const res = await request.get<undefined, any>(`/dc/device/api/v2/changelogbysn?serialNumber=${detail.serial_number}`)
    const temp_logs = res.map((v: any, i: number) => ({ ...flatObj(v), label: moment(v.updated_at).format('YYYY-MM-DD HH:mm:ss'), value: i, org_name: detail.org_name }))
    setLogs(temp_logs)
    setActive(0)
    setRenderList([temp_logs[0], temp_logs[0]]);
  }

  useEffect(() => {
    fetchLogs().catch();
    setCheckedList(SwitchType.none)
  }, [detail, open])
  
  return <>
    <div className={styles.header}>
      <div>历史版本: <Select value={active} onChange={(v) => handleSelect(v)} options={logs} /></div>
      <div>对比本次修改: <Switch checked={!!(checkedList & SwitchType.pre)} size='small' onChange={handleChangeDiff(SwitchType.pre)} /></div>
      <div>对比当前差异: <Switch checked={!!(checkedList & SwitchType.cur)} size='small' onChange={handleChangeDiff(SwitchType.cur)} /></div>
    </div>
    {detailConfig.map(v => <Fragment key={v.title}>
      <p className={styles.detail_tile}>{v.title}</p>
      <div className={styles.detail_item}>
        {v.detail.map(v => <div key={v.value}>
          <p>{v.label}:</p> 
          <div>
            {renderList[0]?.[v.value] !== renderList[1]?.[v.value] && <><span style={{color: 'red', textDecoration: 'line-through'}}>{v.render ? v.render(v.value, renderList[0] || {}) : renderList[0]?.[v.value]}</span> -&gt; </>}
            <span>{v.render ? v.render(v.value, renderList[1] || {}) : renderList[1]?.[v.value]}</span>
          </div>
        </div>)}
      </div>
    </Fragment>)}
  </>
}
