import { Modal, Pagination, Select, Table } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import React, { useEffect, useState } from 'react'
import { getOrgList } from '../../utils/request/dcApi'
import { deviceColumn } from './config'
import { FetchAccuracyApi } from '../../assets/types/apiTypes'
import { request } from '../../utils/request'
import styles from './index.module.less'
import { flatObj } from '../../utils/common'
import { DetailItem } from './component/DetailItem'

export default () => {
  const [orgList, setOrgList] = useState<DefaultOptionType[]>([])
  const [filterInfo, setFilterInfo] = useState<FetchAccuracyApi>({page_num: 1, page_size: 10})
  const [data, setDate] = useState<any[]>([])
  const [detail, setDetail] = useState<any>({})
  const [showModal, setShowModal] = useState(false)

  const initOrgList = async () => {
    const res = await getOrgList()
    const org_list = res.map(v => ({ label: v.name, value: v.id, key: v.id }))
    setOrgList(org_list)
    searchPage(org_list);
  }

  const handleChangeFilter = (query: any) => {
    const resQuery = { ...filterInfo, ...query }
    setFilterInfo(resQuery)
    searchPage(orgList, resQuery)
  }

  const searchPage = async (orgList: DefaultOptionType[], query: Partial<FetchAccuracyApi> = {}) => {
    try {
      const res = await request<FetchAccuracyApi, any>('/dc/device/api/v2/listdevice', { method: 'post',data: { ...query } })
      setDate(res.records.map((v: any) => ({ ...v, org_name: orgList.find(val => val.value === v.org_id)?.label })))

      setFilterInfo({page_num: res.current, page_size: res.size, total: res.total})

    } catch (error) {
      
    }
  }

  const handleDetail = (record: any) => {
    setDetail(flatObj(record))
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  useEffect(() => {
    initOrgList().catch()
  }, [])

  return <div className={styles.container}>
    <div className={styles.header}>
      <Select allowClear placeholder='请选择机构' onChange={(v) => handleChangeFilter({org_id: v})} options={orgList} />
    </div>
    <Table
      dataSource={data}
      columns={deviceColumn(handleDetail)}
      pagination={false}
      className='scroll-table'
      scroll={{ y: 'auto' }}
      rowKey='id'
    />
    <Pagination
        current={filterInfo.page_num}
        pageSize={filterInfo.page_size}
        total={filterInfo.total}
        onChange={(val) => handleChangeFilter({ page_num: val })}
        style={{textAlign: 'end', marginTop: '10px'}}
    />
    <Modal
      open={showModal}
      onOk={handleClose}
      onCancel={handleClose}
      className={styles.detail_modal}
      style={{ top: 50 }}
    >
      <DetailItem detail={detail} open={showModal} />
    </Modal>
  </div>
}