type CommonObj = {
  [props: string]: CommonObj | any;
};

export const flatObj = (obj: CommonObj, pre = "") => {
  let res: CommonObj = {};

  for (let key in obj) {
    if (obj[key]?.toString() === "[object Object]") {
      res = { ...res, ...flatObj(obj[key], pre ? `${pre}.${key}` : key) };
    } else {
      res[pre ? `${pre}.${key}` : key] = obj[key];
    }
  }

  return res;
};
